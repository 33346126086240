import { APIAttachment } from '@/APIModels/recipients/types';
import { UIRecipient } from '@/UIModels/recipient';
import { APISaveAttachmentResponse, SaveResult } from '@/types';
import axios from 'axios';
import { APIRoute, EP } from '@/api-endpoints';
import { UIError } from '@/UIModels/error';
import { UISuccess } from '@/UIModels/success';
import { parseDateUiFromDateTime, sanitizeDateApi } from '@/utilities/date-utils';
import { buildErrorResult } from '@/utils';

export class UIAttachmentFile {
  public category?: string;
  public dateUploaded?: string;
  public fileName?: string;
  public fileType?: string;
  public description?: string;
}

export class UIAttachment {
  public apiSource: APIAttachment|null = null;

  public id: string|null = null;

  public createdAt: string|null = null;
  public updatedAt: string|null = null;
  public createdBy: string|null = null;
  public updatedBy: string|null = null;

  public originalFilename: string|null = null;
  public filename: string|null = null;

  public categoryCode: string|null = null;

  public clinicalAttachments?: FileList;
  public uploadedFiles?: UIAttachmentFile[];
  public description: string|null = null;
  public mimeType: string|null = null;
  public uuid: string|null = null;
  public deleted: boolean|null = null;
  public url: string|null = null;

  public permitted_actions: string[] = []; 

  // Define new UI view model structure
  public constructor(apiAttachment: APIAttachment|null = null) {
    if (apiAttachment) this.updateFromAPIResponse(apiAttachment);
  }

  public get isNew(): boolean {
    return this.apiSource == null;
  }

  // Map from API data structure to UI model structure
  public updateFromAPIResponse(apiAttachment: APIAttachment) {
    this.apiSource = apiAttachment;

    this.id = apiAttachment._id?.$oid || null;
    this.originalFilename = apiAttachment.original_filename || null;
    this.categoryCode = apiAttachment.category_code || null;
    this.filename = apiAttachment.original_filename || null;
    this.description = apiAttachment.description || null;
    this.createdAt = parseDateUiFromDateTime(apiAttachment.created_at) || null;
    this.updatedAt = parseDateUiFromDateTime(apiAttachment.updated_at) || null;
    this.createdBy = apiAttachment.created_by || null;
    this.updatedBy = apiAttachment.updated_by || null;
    this.mimeType = apiAttachment.mime_type || null;
    this.uuid =  apiAttachment.uuid || null;
    this.deleted = apiAttachment.deleted || null;
    this.url = apiAttachment.url || null;

    this.permitted_actions = apiAttachment.permitted_actions || [];
  }

  // Build a copy of the view model
  public copyViewModel() {
    const apiAttachment = this.apiSource as APIAttachment;
    if (!apiAttachment) return new UIAttachment;

    return new UIAttachment(apiAttachment);
  }

  // Generate request payload parameters to provide to API for a Recipient Document patch
  public extractPatch(selected: UIAttachment, recipientId: string|null): any {
    const payload: FormData = new FormData();

    payload.append('attachment[description]', selected.description || '');
    payload.append('attachment[category_code]', selected.categoryCode || '');
    payload.append('attachment[original_filename]', selected.filename || '');

    // We only allow the file to be uploaded on create, not on update
    if (selected.clinicalAttachments && selected.clinicalAttachments[0]) {
      payload.append('attachment[file]', selected.clinicalAttachments[0]);
    }

    return payload;
  }

  // Save edit state to the backend
  public save(opts: { selected: UIAttachment, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {

      const selected: UIAttachment|null = opts.selected || null;
      const recipientId: string|null = opts.recipient.clientId || null;
      // check for blank content
      if (!recipientId) reject((new UIError('recipient_documents')));
      if (!selected) reject((new UIError('recipient_documents')));

      // continue processing
      const payload = this.extractPatch(selected, recipientId);

      const headers = {
        'Content-Type': 'multipart/form-data'
      };

      let method: any;
      let ep: string;

      if (selected.id) {
        method = axios.patch;
        ep = APIRoute(EP.recipients.attachments.update, [[':recipient_id', recipientId as string], [':id', selected.id]]);
      } else {
        method = axios.post;
        ep = APIRoute(EP.recipients.attachments.create, [[':recipient_id', recipientId as string]]);
      }

      method(ep, payload, headers).then((response: APISaveAttachmentResponse) => {
        if (response.data.errors) {
          reject((new UIError('recipient_documents', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        // if 403 alert user, as there will be no server response
        if (errorResponse.response.status === 403) {
          const forbiddenResponse = buildErrorResult(errorResponse.message);
          reject(forbiddenResponse);
        // otherwise send error response
        } else {
          reject((new UIError('recipient_documents', errorResponse)).errorResult);
        }
      });
    });
  }

  public generateDownloadLink(opts: { selected: UIAttachment, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {

      const selectedId = opts.selected && opts.selected.id || null;
      const recipientId = opts.recipient.clientId || null;
      // check for blank content
      if (!recipientId) reject((new UIError('recipient_documents')));
      if (!selectedId) reject((new UIError('recipient_documents')));

      const ep = APIRoute(EP.recipients.attachments.show, [[':recipient_id', recipientId as string], [':id', selectedId as string]]);
      axios.get(ep).then((response: any) => {
        if (response.data.errors) {
          reject((new UIError('recipient_documents', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        reject((new UIError('recipient_documents', errorResponse)).errorResult);
      });
    });
  }

  public delete(opts: { selected: UIAttachment, recipient: UIRecipient }): Promise<SaveResult> {
    return new Promise<SaveResult>((resolve, reject) => {

      const selectedId = opts.selected && opts.selected.id || null;
      const recipientId = opts.recipient.clientId || null;
      // check for blank content
      if (!recipientId) reject((new UIError('recipient_documents')));
      if (!selectedId) reject((new UIError('recipient_documents')));

      const ep = APIRoute(EP.recipients.attachments.update, [[':recipient_id', recipientId as string], [':id', selectedId as string]]);
      axios.delete(ep).then((response: APISaveAttachmentResponse) => {
        if (response.data.errors) {
          reject((new UIError('recipient_documents', response)).errorResult);
        } else {
          // Success! We may need to update the current page
          resolve((new UISuccess(response)).getSaveResult());
        }
      }).catch((errorResponse: any) => {
        // if 403 alert user, as there will be no server response
        if (errorResponse.response.status === 403) {
          console.log('error trying to delete file');
          const forbiddenResponse = buildErrorResult(errorResponse.message);
          reject(forbiddenResponse);
        // otherwise send error response
        } else {
          reject((new UIError('recipient_documents', errorResponse)).errorResult);
        }
      });
    });
  }
}
