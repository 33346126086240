<template>
  <form v-if="config && config.url" :action="config.url" v-on:submit="(event) => prepareRequest('oneid')" method="post">
    <input type="submit" :value="$t(config.title || '')" class="btn btn-success login float-right"/>
  </form>
</template>

<script lang="ts">
import { Component, mixins } from 'vue-facing-decorator';
import { UrlUtilsMixin } from "@/mixins/url-utils-mixin";
import { APIBaseRoute, APINakedRoute, EP } from '@/api-endpoints';
import { i18nMessages } from '@/i18n';
import { useCurrentPageStore } from '@/stores/currentPage';

export interface ButtonConfiguration {
  title: string|null;
  url: string|null;
}

@Component({
  ...i18nMessages([
    require('@/components/login/_locales/common.json'),
  ]),
})
export default class Login extends mixins(UrlUtilsMixin) {
  config: ButtonConfiguration = { title: null, url: null };

  get getLoginProviderName(): string {
    return useCurrentPageStore().configuration.loginProvider;
  }

  getConfigurations(type: string|null): ButtonConfiguration {
    switch(type) {
      case 'ehealth':
        return {
          title: 'login_ehealth',
          url: APIBaseRoute(EP.loginProvider.eHealth)
        };
        break;
      case 'azure_activedirectory_v2':
        return {
          title: 'login_azure',
          url: APINakedRoute(EP.loginProvider.azure),
        };
        break;
      default: 
        return { title: null, url: null };
        break;
    }
  }

  mounted(): void {
    this.config = this.getConfigurations(this.getLoginProviderName || null);
  }
}

</script>
