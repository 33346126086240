import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "legend-title" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "standard-form-group" }
const _hoisted_4 = {
  key: 1,
  class: "mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_toolbar = _resolveComponent("action-toolbar")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_loading_row = _resolveComponent("loading-row")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_save_toolbar = _resolveComponent("save-toolbar")!
  const _component_form_layout = _resolveComponent("form-layout")!
  const _component_validation_observer = _resolveComponent("validation-observer")!

  return (_openBlock(), _createBlock(_component_validation_observer, { ref: "validations" }, {
    default: _withCtx(() => [
      _createVNode(_component_form_layout, {
        disabled: !_ctx.enableForm,
        "form-id": "patient-address-form"
      }, {
        title: _withCtx(() => [
          _createElementVNode("legend", null, [
            _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.selection.isNew ? _ctx.$t('new_patient_address_heading') : _ctx.$t('selected_patient_address_heading')), 1)
          ])
        ]),
        action: _withCtx(() => [
          _createVNode(_component_action_toolbar, {
            ref: "addressActionToolbar",
            onArchive: _ctx.handleArchive,
            onRestore: _ctx.handleRestore,
            permittedActions: _ctx.editState.permittedActions,
            isArchiving: _ctx.isArchiving,
            isRestoring: _ctx.isRestoring,
            loading: _ctx.isLoadingActions
          }, null, 8, ["onArchive", "onRestore", "permittedActions", "isArchiving", "isRestoring", "loading"])
        ]),
        contents: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_select_input, {
                ruleKey: "patient_address.type",
                "select-id": "address-category",
                name: _ctx.$t('address_field.category'),
                options: _ctx.categoryOptions,
                modelValue: _ctx.editState.categoryType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editState.categoryType) = $event)),
                onChange: _ctx.handleCategoryChanged
              }, null, 8, ["name", "options", "modelValue", "onChange"])
            ])
          ]),
          (_ctx.isLoadingForm)
            ? (_openBlock(), _createBlock(_component_loading_row, { key: 0 }))
            : (_ctx.editState.categoryType)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.addressFormatComponentName)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.addressFormatComponentName), {
                        key: 0,
                        ref: "addressFormatComponent",
                        editState: _ctx.editState,
                        onCountryChanged: _ctx.handleCountryChanged
                      }, null, 40, ["editState", "onCountryChanged"]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_4, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['far', 'exclamation-circle'],
                          "fixed-width": ""
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('address_error.missing_format_configuration', { formatCode: _ctx.selectedCategoryFormatCode })), 1)
                      ]))
                ], 64))
              : _createCommentVNode("", true)
        ]),
        save: _withCtx(() => [
          _createVNode(_component_save_toolbar, {
            show: _ctx.showSaveToolbar,
            disabled: !_ctx.enableSaveToolbar,
            ref: "savePatientAddress",
            label: _ctx.$t('save_patient_address'),
            cancelButton: true,
            onSave: _ctx.handleSave,
            onCancel: _ctx.handleCancel
          }, null, 8, ["show", "disabled", "label", "onSave", "onCancel"])
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _: 1
  }, 512))
}