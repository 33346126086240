<template>
  <sub-section
    :title="$t('referral_details')"
    sub-section-id="journey-referral-section"
  >
    <template v-slot:contents v-if="editState">
      <validation-observer ref="validations">
        <form-layout form-id="journey-referral" :disabled="!canSave || newJourney">
          <template v-slot:contents >
            <!-- Referral Details -->
            <form-layout form-id="journey-referral-details">
              <template v-slot:contents >
                <div class="row">
                  <div class="standard-form-group">
                    <date-input
                      ruleKey="referral_details.received_date"
                      :name="$t('referral_received_date')"
                      input-id="rd-recieved-date"
                      v-model="editState.receivedDate"
                    />
                  </div>
                  <div class="standard-form-group">
                    <select-input
                      ruleKey="referral_details.referral_source"
                      select-id="rd-referral-source"
                      :name="$t('referral_source')"
                      :options="referralSourceOptions"
                      v-model="editState.referralSource"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="standard-form-group">
                  <select-input
                    ruleKey="referral_details.stage_attributes.journey.failed_organ.primary_diagnosis_code"
                    select-id="rd-primary-diagnosis"
                    @change="checkSecondDiagnosis(editState.primaryDiagnosisCode)"
                    :name="$t('primary_diagnosis')"
                    v-model="editState.primaryDiagnosisCode"
                    :options="diseaseCodeLookup"
                  />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      ruleKey="referral_details.stage_attributes.journey.failed_organ.primary_diagnosis_date"
                      input-id="rd-primary-diagnosis-date"
                      :name="$t('primary_diagnosis_date')"
                      v-model="editState.primaryDiagnosisDate"
                    />
                  </div>
                  <div class="standard-form-group">
                  <select-input
                    ruleKey="referral_details.stage_attributes.journey.failed_organ.secondary_diagnosis_code"
                    select-id="rd-secondary-diagnosis"
                    :name="$t('secondary_diagnosis')"
                    @change="checkSecondDiagnosisForBlankValue(editState.secondaryDiagnosisCode)"
                    v-model="editState.secondaryDiagnosisCode"
                    :options="secondDiseaseCodeLookup"
                  />
                  </div>
                  <div class="standard-form-group">
                    <date-input
                      ruleKey="referral_details.stage_attributes.journey.failed_organ.secondary_diagnosis_date"
                      input-id="rd-secondary-diagnosis-date"
                      :name="$t('secondary_diagnosis_date')"
                      v-model="editState.secondaryDiagnosisDate"
                    />
                  </div>
                </div>
              </template>
            </form-layout>
            <!-- Referring Physician -->
            <form-layout form-id="referral-details-physician">
              <template v-slot:title>
                <legend>
                  <h5 class="legend-title">
                    {{ $t('referring_physician_details') }}
                  </h5>
                </legend>
              </template>
              <template v-slot:contents>
                <!-- Referrer Professional -->
                <div class="row">
                  <div class="standard-form-group">
                    <text-input
                      rulekey="referral_details.referrer.organization.name"
                      :name="$t('referring_organization_name')"
                      input-id="rd-referring-name"
                      v-model="editState.referringOrganizationName"
                    />
                  </div>
                  <div class="standard-form-group">
                    <select-input
                      rulekey="referral_details.referrer.organization.type_code"
                      select-id="rd-referring-type"
                      :name="$t('referring_organization_type')"
                      :options="organizationTypeLookup"
                      v-model="editState.referringOrganizationType"
                    />
                  </div>
                  <div class="standard-form-group-large">
                    <select-input
                      rulekey="referral_details.referrer.professional.type_code"
                      select-id="rd-professional-type"
                      :name="$t('referring_health_care_professional_type')"
                      :options="professionalTypeLookup"
                      v-model="editState.referringProfessionalType"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="standard-form-group">
                    <text-input
                      rulekey="referral_details.referrer.professional.first_name"
                      input-id="rd-first-name"
                      :name="$t('referrer_first_name')"
                      v-model="editState.firstName"
                    />
                  </div>
                  <div class="standard-form-group">
                    <text-input
                      rulekey="referral_details.referrer.professional.last_name"
                      input-id="rd-last-name"
                      :name="$t('referrer_last_name')"
                      v-model="editState.lastName"
                    />
                  </div>
                </div>

                <!-- Referrer Address -->
                <div class="row">
                  <div class="standard-form-group">
                    <select-input
                      ruleKey="referral_details.referrer.address.country"
                      selectId="rd-country"
                      :name="$t('referrer_address.country')"
                      v-model="editState.country"
                      :options="countryOptions"
                      @change="handleCountryChanged"
                      :filterByLookupRule="true"
                    />
                  </div>
                  <template v-if="editState.country === getCountryValue.Canada">
                    <div class="standard-form-group">
                      <select-input
                        ruleKey="referral_details.referrer.address.province"
                        selectId="rd-province"
                        :name="$t('referrer_address.province_or_territory')"
                        validationId="rd-province"
                        v-model="editState.province"
                        :options="provinceOptions"
                      />
                    </div>
                  </template>
                  <template v-else-if="editState.country === getCountryValue.USA">
                    <div class="standard-form-group">
                      <select-input
                        ruleKey="referral_details.referrer.address.province"
                        selectId="rd-state"
                        :name="$t('referrer_address.state_or_territory')"
                        validationId="rd-state"
                        v-model="editState.state"
                        :options="stateOptions"
                      />
                    </div>
                  </template>
                  <template v-else-if="editState.country === getCountryValue.Other">
                    <div class="standard-form-group">
                      <text-input
                        ruleKey="referral_details.referrer.address.other_country"
                        inputId="rd-other-country"
                        :name="$t('referrer_address.other_country')"
                        v-model="editState.otherCountry"
                      />
                    </div>
                  </template>
                  <template v-if="editState.country">
                    <div class="row-break d-none d-lg-block d-xxl-none"></div>
                    <div class="standard-form-group-large">
                      <text-input
                        ruleKey="referral_details.referrer.address.street"
                        inputId="rd-street-address"
                        :name="$t('referrer_address.street_address')"
                        v-model="editState.streetAddress"
                      />
                    </div>
                    <div class="standard-form-group-large" v-if="editState.showUrbanization">
                      <text-input
                        ruleKey="referral_details.referrer.address.urbanization"
                        inputId="rd-urbanization"
                        :name="$t('referrer_address.urbanization')"
                        v-model="editState.urbanization"
                      />
                    </div>
                    <div class="standard-form-group">
                      <text-input
                        ruleKey="referral_details.referrer.address.city"
                        inputId="rd-city"
                        :name="$t('referrer_address.city')"
                        v-model="editState.city"
                      />
                    </div>
                    <div class="standard-form-group" v-if="editState.showPostalCode">
                      <text-input
                        ruleKey="referral_details.referrer.address.postal_code"
                        inputId="rd-postal-code"
                        :name="$t('referrer_address.postal_code')"
                        v-model="editState.postalCode"
                      />
                    </div>
                    <div class="standard-form-group" v-else-if="editState.showZipCode">
                      <text-input
                        ruleKey="referral_details.referrer.address.postal_code"
                        inputId="rd-zip-code"
                        :name="$t('referrer_address.zip_code')"
                        v-model="editState.zipCode"
                      />
                    </div>
                  </template>
                </div>

                <!-- Referrer Organization Contact -->
                <div class="row">
                  <div class="standard-form-group">
                    <text-input
                      ruleKey="referral_details.referrer.organization.phone_number"
                      inputId="rd-phone-number"
                      :name="$t('referrer_contact.phone_number')"
                      v-model="editState.phoneNumber"
                      :mask="getMaskFromRules('referral_details.referrer.organization.phone_number')"
                    />
                  </div>
                  <div class="standard-form-group">
                    <text-input
                      ruleKey="referral_details.referrer.organization.fax_number"
                      inputId="rd-fax-number"
                      :name="$t('referrer_contact.fax_number')"
                      v-model="editState.faxNumber"
                      :mask="getMaskFromRules('referral_details.referrer.organization.fax_number')"
                    />
                  </div>
                  <div class="standard-form-group">
                    <text-input
                      ruleKey="referral_details.referrer.organization.email"
                      inputId="rd-email"
                      :name="$t('referrer_contact.email')"
                      v-model="editState.emailAddress"
                      :mask="getMaskFromRules('referral_details.referrer.organization.email')"
                    />
                  </div>
                </div>


                <!-- Referral Comments -->
                <div class="row">
                  <div class="standard-full-width-group">
                    <text-area-input
                      ruleKey="referral_details.comments"
                      inputId="rd-comments"
                      :name="$t('referral_comments')"
                      v-model="editState.comments"
                    />
                  </div>
                </div>
              </template>
            </form-layout>
          </template>
          <template v-slot:save>
            <save-toolbar
              :show="showSaveToolbar"
              ref="saveReferralDetails"
              :label="$t('save_referral_details')"
              @save="handleSave"
              :cancelButton="true"
              @cancel="handleCancel"
            />
          </template>
        </form-layout>
      </validation-observer>
    </template>
  </sub-section>
</template>

<script lang="ts">

import { mixins } from "vue-facing-decorator";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-facing-decorator';
import { Recipient } from '@/store/recipients/types';
import { Country, CountryValue, Organ, OrganDiseaseCode, OrganizationType, ProfessionalType, Province } from '@/store/lookups/types';
import TextInput from '@/components/shared/TextInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from "@/components/shared/SelectInput.vue";
import { Component, Prop } from 'vue-facing-decorator';
import { IdLookup } from '@/store/validations/types';
import CountryInput from '@/components/shared/CountryInput.vue';
import { RulesQuery, SaveResult } from '@/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { sortOptionsByValue } from '@/utils';
import { GenericCodeValue } from "@/store/types";
import FormLayout from '@/components/shared/FormLayout.vue';
import { i18nMessages } from "@/i18n";
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import { UIReferralDetails } from "@/UIModels/journey/referralDetails";
import { useCurrentPageStore } from '@/stores/currentPage';
import { UIRecipient } from "@/UIModels/recipient";
import { UIJourney } from "@/UIModels/journey";
import { EP } from '@/api-endpoints';
import { parseFormErrors } from '@/utils';

@Component({
  components: {
    DateInput,
    TextInput,
    SubSection,
    SelectInput,
    CountryInput,
    FormLayout,
    SaveToolbar,
    TextAreaInput,
  },
  ...i18nMessages([
    require('@/components/organs/shared/_locales/ReferralDetailsSection.json'),
  ]),
})
export default class ReferralDetailsSection extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.lookups.organization_type) organizationTypeLookup!: OrganizationType[];
  @State(state => state.lookups.professional_type) professionalTypeLookup!: ProfessionalType[];
  @State(state => state.lookups.referral_source) referralSourceOptions!: GenericCodeValue[];

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('country', { namespace: 'lookups' }) private countryOptions!: Country[];
  @Getter('province', { namespace: 'lookups' }) private provinceOptions!: Province[];
  @Getter('us_state', { namespace: 'lookups' }) private stateOptions!: Province[];
  @Getter('getMaskFromRules', { namespace: 'validations' }) private getMaskFromRules!: (ruleKey: string) => string|null;

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Editable view model for the form
  private editState = new UIReferralDetails();

  get getCountryValue(): any {
    return CountryValue;
  }

  // Can we show the save toolbar?
  private get showSaveToolbar(): boolean {
    return this.canSave;
  }

  async mounted(): Promise<void> {
    this.initializeForm();
    this.loadEditRules();
  }

  // What validation rule query parameters are needed based on current form edit state?
  get ruleQueryEditState(): RulesQuery {
    const params: RulesQuery = {};
    const selectedCountryCode = this.editState.country;

    if (selectedCountryCode) params['country'] = selectedCountryCode;
    return params;
  }

  // Load #edit rules with specified query parameters
  loadEditRules(): Promise<void> {
    return this.$store.dispatch('validations/loadValidationsWithActions', {
      route: EP.recipients.journeys.referral.edit,
      payload: [[':recipient_id', this.recipientId], [':journey_id', this.journeyId]],
      prefix: 'referral_details',
      query: this.ruleQueryEditState,
    });
  }

  handleCancel(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    const currentPageStore = useCurrentPageStore();
    const uiJourney = currentPageStore.currentJourney as UIJourney;
    const selected = uiJourney.referralDetails;
    this.editState = selected ? selected.copyViewModel() : new UIReferralDetails();
  }

  public checkSecondDiagnosis(code :any): void {
    // if secondaryDiagnosisCode is the same as new firstDiagnosisCode set secondaryDiagnosisCode back to undefined and null
    if (this.editState && this.editState.primaryDiagnosisCode == this.editState.secondaryDiagnosisCode) {
      this.editState.secondaryDiagnosisCode = null;
      this.editState.secondaryDiagnosisDate = '';
    }
  }

  checkSecondDiagnosisForBlankValue(code: any): void {
    if (this.editState && code.length == 0) {
      this.editState.secondaryDiagnosisDate = '';
    }
  }

  /**
   *  Show or hide elements base on country type
   */
  get showIfNotOtherCountry(): boolean {
    if(this.editState === undefined) return true;
    return this.editState.country  !== CountryValue.Other;
  }
  
  /**
   *  Check Country is other
   */
  get checkOtherCountry(): boolean {
    if(this.editState === undefined) return true;
    return this.editState.country === CountryValue.Other ;
  }

  // Reference save toolbar
  get saveToolbar(): SaveToolbar|null {
    const saveToolbar = this.$refs.saveReferralDetails;
    if (!saveToolbar) return null;

    return saveToolbar as SaveToolbar;
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Referral Details, dispatches a save action, and registers the save result.
   *
   * @emits save
   */
  public async handleSave(): Promise<void> {
    this.resetFormErrors();
    if (this.saveToolbar) this.saveToolbar.startSaving();
    const currentPageStore = useCurrentPageStore();
    const params = {
      recipient: currentPageStore.currentRecipient as UIRecipient,
      journey: currentPageStore.currentJourney as UIJourney,
    };
    try {
      const success: SaveResult = await this.editState.save(params);
      this.handleSuccess(success);
    } catch (error: unknown) {
      this.handleErrors(error as SaveResult);
    }
  }

  // Resets Form Errors
  private resetFormErrors(): void {
    const validations = this.$refs.validations as any;
    if (validations) validations.resetForm();
  }

  // Process successful save result
  private handleSuccess(success: SaveResult): void {
    if (this.saveToolbar) this.saveToolbar.stopSaving(success);
  }

  // Process error save result
  private handleErrors(errors: SaveResult): void {
    // Derive errors for UI input fields based on API error results
    const formErrors: any = parseFormErrors(errors, this.idLookup);

    // inject api errors into vee-validate
    const validationObserver = this.$refs.validations as any;
    if (validationObserver) validationObserver.setErrors(formErrors);

    if (this.saveToolbar) this.saveToolbar.stopSaving(errors);
  }

  /**
   * Resets the save toolbar
   */
  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handle the areas present on this form component
    const saveToolbar = this.$refs.referralDetails as unknown as SaveToolbar;
    if (saveToolbar) saveToolbar.reset();
  }

  /**
   * Returns an array of options for Absolute Contraindication
   *
   * Determines if we have any values in the Organ sub_table for the selected Journey
   *
   * @returns {OrganDiseaseCode[]} all options for absolute contraindication
   */
  get diseaseCodeLookup(): OrganDiseaseCode[] {
    if (!this.organLookup || !this.organCode) {
      return [];
    }
    // Retrieve information based on Journey Organ
    const organLookupEntry = this.organLookup.find((organ: Organ) => {
      return organ.code === this.organCode;
    });
    if (!organLookupEntry || !organLookupEntry.sub_tables) {
      return [];
    }
    // Determine which sub table to fetch options from
    let result: OrganDiseaseCode[] = [];
    const diseaseCode = organLookupEntry.sub_tables.disease_code;
    // Check if there are any disease codes
    if (Array.isArray(diseaseCode) && diseaseCode.length > 0) {
      result = diseaseCode;
    }

    // NOTE: lookup order should determine presentation order (see AP-1668)
    return result;
  }

  get secondDiseaseCodeLookup(): OrganDiseaseCode[] {
    // Get the disease Codes
    let list = this.diseaseCodeLookup;
    // If primaryDiagnosisCode is chosen remove primaryDiagnosisCode from array
    if (this.editState && this.editState.primaryDiagnosisCode != null) {
      let index = this.editState.primaryDiagnosisCode;
      list = list.filter(function(item: any) {
        return item.code != index;
      });
    }
    return list;
  }
  
  /**
   * Return the organ code from the journey or url params
   *
   * @returns {number} organ code or 0 if none
   */
  get organCode(): number {
    if (this.newJourney) return Number(this.$route.params.organ_code);
    return this.journey.organ_code ? this.journey.organ_code : 0;
  }

  // Clear state and province on country change
  private handleCountryChanged(): void {
    this.editState.province = null;
    this.editState.state = null;
    this.editState.otherCountry = '';

    this.loadEditRules();
  }

   // Return value of text of postal code based on country
  get getPostalLabelText(): string {
    const countryCode = this.editState ? this.editState.country : null;
    if (countryCode == CountryValue.USA) {
      return "Zip Code";
    }
    return 'Postal Code';
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: IdLookup = {
    'referral_details.received_date'                      : 'rd-recieved-date',
    'referral_details.referral_source'                    : 'rd-referral-source',

    'referral_details.primary_diagnosis_code'             : 'rd-primary-diagnosis',
    'referral_details.primary_diagnosis_date'             : 'rd-primary-diagnosis-date',
    'referral_details.secondary_diagnosis_code'           : 'rd-secondary-diagnosis',
    'referral_details.secondary_diagnosis_date'           : 'rd-secondary-diagnosis-date',

    'referral_details.referrer.organization.name'         : 'rd-referring-name',
    'referral_details.referrer.organization.type_code'    : 'rd-referring-type',

    'referral_details.referrer.professional.type_code'    : 'rd-professional-type',
    'referral_details.referrer.professional.first_name'   : 'rd-first-name',
    'referral_details.referrer.professional.last_name'    : 'rd-last-name',

    'referral_details.referrer.address.country'           : 'rd-country',
    'referral_details.referrer.address.other_country'     : 'rd-other-country',
    'referral_details.referrer.address.province'          : ['rd-province', 'rd-state'],
    'referral_details.referrer.address.street'            : 'rd-street-address',
    'referral_details.referrer.address.city'              : 'rd-city',
    'referral_details.referrer.address.postal_code'       : ['rd-postal-code', 'rd-zip-code'],
    'referral_details.referrer.address.urbanization'      : 'rd-urbanization',

    'referral_details.referrer.organization.phone_number' : 'rd-phone-number',
    'referral_details.referrer.organization.fax_number'   : 'rd-fax-number',
    'referral_details.referrer.organization.email'        : 'rd-email',
  };
}
</script>
