<template>
  <fieldset v-if="showToolbar || loading">
    <nav class="nav action-row" v-if="loading">
      <span class="skeleton-box skeleton-button-left mb-0" />
    </nav>
    <nav class="nav action-row" v-else>
      <!-- archive -->
      <button
        type="button"
        v-if="canArchive"
        class="btn btn-wide btn-warning mr-2"
        @click.prevent="openArchive"
        :disabled="!canArchive"
      >
        {{ $t('action.archive') }}
        <span class="pl-2" v-if="isArchiving">
          <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
        </span>
      </button>
      
      <!-- restore -->
      <button
        type="button"
        v-if="canRestore"
        class="btn btn-wide btn-primary mr-2"
        @click.prevent="openRestore"
        :disabled="!canRestore"
      >
        {{ $t('action.restore') }}
        <span class="pl-2" v-if="isRestoring">
          <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
        </span>
      </button>

      <!-- delete -->
      <button
        type="button"
        v-if="canDestroy"
        class="btn btn-wide btn-danger mr-2"
        @click.prevent="openDestroy"
        :disabled="!canDestroy"
      >
        {{ destroyButtonText ? destroyButtonText : $t('action.destroy') }}
        <span class="pl-2" v-if="isDestroying">
          <font-awesome-icon class="fa-1x fa-spin" :icon="['far', 'spinner-third']" />
        </span>
      </button>
    </nav>
    <hr />

    <validation-observer ref="actionValidation" autocomplete="off" tag="form" v-slot="{ handleSubmit }">
      <modal-section
        modalId="actionModal" 
        ref="actionModal" 
        class="modal-action-modal"
        :centered="true" 
        :closeButton="false" 
        size="md" 
        :wide="false">
        <template v-slot:title>
          {{ getLocaleText('title') }}
        </template>

        <template v-slot:body>
          <fieldset>
            <small>{{ getGuidingText() }}</small>
            <div class="mt-2" v-if="rationaleRequired">
              <hr/>
              <div class="col-xs-12">
                <text-input
                  rules="required"
                  input-id="archive-rationale"
                  required="true"
                  :name="getLocaleText('rationale')"
                  v-model="rationale"
                />
              </div>
            </div>
          </fieldset>
        </template>

        <template v-slot:footer>
          <div class="modal-footer-body">
            <button
              class="btn btn-success"
              @click.prevent="rationaleRequired ? handleSubmit(performAction): performAction()">
              {{ $t('action.confirm') }}
            </button>
            <button 
              type="button" 
              data-dismiss="modal" 
              @click="cancel"
              class="btn btn-secondary">
              {{ $t('action.cancel') }}
            </button>
          </div>
        </template>
      </modal-section>
    </validation-observer>

  </fieldset>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import TextInput from '@/components/shared/TextInput.vue';
import { i18nMessages } from '@/i18n';
import { APIPermittedActions } from '@/types';

@Component({
  components: {
    ModalSection,
    TextInput
  },
  ...i18nMessages([
    require('@/components/shared/_locales/ActionToolbar.json'),
  ]),
  emits: [
    'archive',
    'restore',
    'destroy',
  ],
})
export default class ActionToolbar extends Vue {
  @Prop({ default: () => { return []; } }) permittedActions!: string[];
  // Is an action operation currently in progress?
  @Prop({ default: false }) isArchiving!: boolean;
  @Prop({ default: false }) isRestoring!: boolean;
  @Prop({ default: false }) isDestroying!: boolean;
  @Prop({ default: false }) rationaleRequired!: boolean;
  @Prop({ default: false }) loading!: true;

  // UI copy props
  @Prop({ default: null }) archiveButtonText!: string;
  @Prop({ default: null }) archiveGuidingText!: string;

  @Prop({ default: null }) restoreButtonText!: string;
  @Prop({ default: null }) restoreGuidingText!: string;

  @Prop({ default: null }) destroyButtonText!: string;
  @Prop({ default: null }) destroyGuidingText!: string;

  private rationale = null
  private action: string|null = null

  get showToolbar(): boolean {
    return this.canArchive || this.canRestore || this.canDestroy;
  }

  // Does the current user have permission to archive?
  get canArchive(): boolean {
    return this.permittedActions.includes(APIPermittedActions.Archive);
  }

  // Does the current user have permission to restore?
  get canRestore(): boolean {
    return this.permittedActions.includes(APIPermittedActions.Restore);
  }

  // Does the current user have permission to destroy?
  get canDestroy(): boolean {
    return this.permittedActions.includes(APIPermittedActions.Destroy);
  }

  getLocaleText(key: string): string {
    if (!key || !this.action) return '';

    return this.$t(`${this.action}.${key}`).toString();
  }

  getGuidingText(): string|null {
    if (!this.action) return null;
    switch (this.action) {
      case APIPermittedActions.Archive:
        return this.archiveGuidingText ? this.archiveGuidingText : this.$t(`${this.action}.guiding_text`).toString();
        break;
      case APIPermittedActions.Restore:
        return this.archiveGuidingText ? this.archiveGuidingText : this.$t(`${this.action}.guiding_text`).toString();
        break;
      case APIPermittedActions.Destroy:
        return this.destroyGuidingText ? this.destroyGuidingText : this.$t(`${this.action}.guiding_text`).toString();
        break;
      default:
        return null;
        break;
    }
  }

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  private openArchive(): void {
    this.resetToolbar();
    this.action = APIPermittedActions.Archive;
    this.toggleModal('actionModal');
  }

  private openRestore(): void {
    this.resetToolbar();
    this.action = APIPermittedActions.Restore;
    this.toggleModal('actionModal');
  }

  private openDestroy(): void {
    this.resetToolbar();
    this.action = APIPermittedActions.Destroy;
    this.toggleModal('actionModal');
  }

  private performAction(): void {
    this.$emit(this.action as string, this.rationale);
    this.toggleModal('actionModal');
  }

  public resetToolbar(): void {
    this.action = null; // clear action
    this.rationale = null; // clear rationale

    // clear form validation
    const validations = this.$refs.actionValidation as any;
    if (validations) { validations.resetForm(); }
  }

  private cancel(): void {
    this.toggleModal('actionModal'); // close modal
  }

}
</script>
