import i18n from '@/i18n';
import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { DashboardState } from '@/store/dashboard/types';
import { AllocationOfferSnapshot, AllocationOfferedRecipient } from '@/store/allocations/types';
import { DashboardOffer,  Notification } from '@/store/dashboard/types';

export const getters: GetterTree<DashboardState, RootState> = {
  getPanelStyles(state) {
    return [
      { code: "full",       value: "Full Width" },
      { code: "half",       value: "Half Width" },
      { code: "one-third",  value: "One Third" },
      { code: "two-thirds", value: 'Two Thirds' }
    ];
  },
  /**
   * Returns notifications
   *
   * @returns {any[]} array of notifications
   */
  getNotifications(state, getters, rootState, rootGetters) {
    const notifications = state.notifications || [];
    const results: any[] = [];
    notifications.map((notification: Notification) => {
      const notifiable_event = notification.notifiable_event ? notification.notifiable_event[0] : null;
      const result = {
        date_time: notifiable_event ? rootGetters['utilities/parseDisplayDateTimeUi'](notifiable_event.event_date) : undefined,
        trigger: notifiable_event ? notifiable_event.event_code : undefined,
        description: notification.short_message,
        unread: notification.unread || true
      };
      results.push(result);
    });
    return results;
  },
  /**
   * Returns any open allocations that have not been accepted in the primary offer; i.e. still include accept with condition
   *
   * @returns {any[]} array of primary offers
   */
  getAllocations(state, getters, rootState, rootGetters) {
    const allocations = state.allocations || [];
    const offers: any[] = [];
    allocations.map((allocation: AllocationOfferSnapshot) => {
      const offer = {
        donor_client_id: allocation.donor_client_id,
        deceased_donor_id: allocation.deceased_donor_id,
        client_id: allocation.client_id,
        organ: rootGetters['lookups/lookupValue'](allocation.organ_code, 'organ'),
        organ_code: allocation.organ_code,
        allocation_type: allocation.allocation_type ? allocation.allocation_type.toLowerCase() : null,
        state: allocation.state || '-',
        offered_recipients: allocation.offered_recipients || []
      };
      // filters offers by program for transplant coordinator
      if (rootGetters['users/isTransplantCoordinator']) {
        if (getters.sameTransplantProgram(offer)) { offers.push(offer); }
      } else {
        offers.push(offer);
      }
    });
    return offers;
  },
  /**
   * Returns primary offers
   *
   * @returns {any[]} array of primary offers
   */
  getPrimaryOffers(state, getters, rootState, rootGetters) {
    const allocations = state.primaryOffers || [];
    return getters.buildOffersFromAllocations(allocations);
  },
  /**
   * Returns backup offers
   *
   * @returns {any[]} array of backup offers
   */
  getBackupOffers(state, getters, rootState, rootGetters) {
    const allocations = state.backupOffers || [];
    return getters.buildOffersFromAllocations(allocations);
  },
  /**
   * Return true if the logged in user is from the same Transplant Program
   *
   * @returns {boolean} true if it's the same transplant program
   */
  sameTransplantProgram(state, getters, rootState, rootGetters) {
    return (row: any): boolean => {
      if (!row) return false;
      const transplantProgram = row.program_identifier ? row.program_identifier.toLowerCase() : '';
      return rootGetters['users/getUsersTransplantPrograms'].includes(transplantProgram);
    };
  },
  /**
   * Returns offers based off data from allocations
   *
   * @returns {any[]} array of offers
   */
  buildOffersFromAllocations(state, getters, rootState, rootGetters) {
    return (allocations: any[]): any[] => {
      const offers: DashboardOffer[] = [];
      allocations.map((allocation: AllocationOfferSnapshot) => {
        const recipients = allocation.offered_recipients || [];
        recipients.map((recipient: AllocationOfferedRecipient) => {
          const offer = {
            donor_client_id: allocation.donor_client_id,
            program_identifier: recipient.program_identifier,
            deceased_donor_id: allocation.deceased_donor_id,
            client_id: allocation.client_id,
            organ: rootGetters['lookups/lookupValue'](allocation.organ_code, 'organ'),
            organ_code: allocation.organ_code,
            offered_organ_code: recipient.organ_code,
            offered_organ_specification_code: recipient.organ_specification_code,
            offered_organ: rootGetters['lookups/getOrganSpecificationName'](recipient.organ_code, recipient.organ_specification_code),
            allocation_type: allocation.allocation_type ? allocation.allocation_type.toLowerCase() : null,
            recipient_client_id: recipient.client_id,
            datetime_offered: rootGetters['utilities/parseDisplayDateTimeUi'](recipient.datetime_offered),
            datetime_expires: rootGetters['utilities/parseDisplayDateTimeUi'](recipient.datetime_expires),
            // Use i18n translation to show correct allocation state
            state: i18n.tc(`offer_status.${allocation.state}`).toString() || '-',
            offer_response_code: recipient.offer_response_code,
            offer_type_code: recipient.offer_type_code
          };
          // filters offers by program for transplant coordinator
          if (rootGetters['users/isTransplantCoordinator']) {
            if (getters.sameTransplantProgram(offer)) { offers.push(offer); }
          } else {
            offers.push(offer);
          }
        });
      });
      return offers;
    };
  },
  /**
   * Returns active donors
   *
   * @returns {any[]} array of active donors
   */
  getActiveDonors(state, getters, rootState, rootGetters) {
    return state.activeDonors;
  },
  /**
   * Returns recent recipients
   *
   * @returns {any[]} array of recent recipients
   */
  getRecentRecipients(state, getters, rootState, rootGetters) {
    return state.recentRecipients;
  },
  /**
  * Returns living donors
  *
  * @returns {any[]} array of living donors
  */
  getRecentLivingDonors(state, getters, rootState, rootGetters) {
    return state.recentLivingDonors;
  },
  /**
  * Returns Donor Lab Results
  *
  * @returns {LabResult[]} array of labs results.
  */
  getLabResults(state, getters, rootState, rootGetters) {
    return state.labResults;
  },
  /**
   * Returns urgent listings
   *
   * @returns {any[]} array of urgent listings
   */
   getUrgentListings(state, getters, rootState, rootGetters) {
    return state.urgentListings;
  },
  /**
   * Returns urgent listings
   *
   * @returns {any[]} array of urgent listings
   */
   getExdDonors(state, getters, rootState, rootGetters) {
    return state.exdDonors;
  },
  /**
   * Returns transplant in progress
   *
   * @returns {any[]} array of transplant in progress
   */
  getTransplantInProgress(state, getters, rootState, rootGetters) {
    return state.transplantInProgress;
  },
  /**
   * Returns widget permissions
   *
   * @returns {any[]} array of widget permissions based on role
   */
  getWidgetRolePermissions(state, getters) {
    return state.widgetRolePermissions;
  }
};
