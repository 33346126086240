<template>
  <validation-provider
    ref="provider"
    :rules="formRules"
    :name="inputId"
    :label="name"
    :vid="validationId ? validationId : inputId"
    v-model="validatedValue"
    v-slot="{ field, errors }">
    <label :for="inputId" :class="{ 'sr-only': hideLabel }">
      {{label || name}}
      <!-- don't show asterisk if disabled -->
      <validation-asterisk
        v-if="!disabled"
        :rules="formRules"
        :crossValues="crossValues"
        :ruleKey="ruleKey"
      />
      <slot name="link" />
    </label>
    <!-- TODO: ensure that the Calculated indicator is accessible to screen readers -->
    <small v-if="calculated" class="form-text text-muted" :title="$t('calculated')">
      <font-awesome-icon class="text-grey" :icon="['far', 'exclamation-circle']" fixed-width />
      {{calculatedText}}
    </small>
    <span class="mobile-spacing-wrapper">
      <template v-if="isMasked">
        <span v-if="isLoading" class="skeleton-box w-100" />
        <input
          v-else
          type="text"
          :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
          :id="inputId"
          :disabled="true"
          :readonly="true"
          :value="modelValue"
        />
      </template>
      <template v-else-if="append">
        <div class="input-group mb-3">
          <span v-if="isLoading" class="skeleton-box w-100" />
          <input
            v-else
            :id="inputId"
            type="text"
            :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !isReadOnly(readonly), 'form-control-plaintext': isReadOnly(readonly) }"
            v-bind="field"
            :readonly="isReadOnly(readonly||disabled)"
            v-on="maskedInputEvents()"
            :placeholder="getSettings.placeholder"
            :aria-label="getSettings.ariaLabel"
            :v-maska="getSettings.vMask"
          />
          <div class="input-group-append">
            <span class="input-group-text">{{appendText}}</span>
          </div>
          <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
            <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
            {{ translateError(errors, label || name) }}
          </div>
        </div>
      </template>
      <template v-else-if="!legacy">
        <span v-if="isLoading" class="skeleton-box w-100" />
        <duet-date-picker
          v-else
          ref="myTestingPicker"
          :identifier="inputId"
          type="text"
          :value="datePickerValue"
          :direction="direction || 'left'"
          :class="{ 'is-invalid': !disabled && errors[0] }"
          :disabled="isReadOnly(readonly||disabled)"
          v-on="maskedInputEvents()"
          :tabindex="isReadOnly(readonly||disabled) ? '0': '-1'"
          :localization.prop="getLocalizationSettingsForDuet"
          :dateAdapter.prop="dateAdapter()"
          aria-label="getSettings.ariaLabel"
          :min="getMinDateLimit"
          :max="getMaxDateLimit"
        />
        <div class="invalid-feedback" :id="`${inputId}-error`" v-if="errors[0]">
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          {{ translateError(errors, label || name) }}
        </div>
      </template>
      <template v-else>
        <span v-if="isLoading" class="skeleton-box w-100" />
        <input
          v-else
          :id="inputId"
          type="text"
          :class="{ 'is-invalid': !disabled && errors[0], 'form-control': !readonly, 'form-control-plaintext': readonly }"
          v-bind="field"
          :tabindex="isReadOnly(readonly||disabled) ? '0': '-1'"
          :readonly="isReadOnly(disabled||readonly)"
          v-on="maskedInputEvents()"
          :placeholder="getSettings.placeholder"
          :aria-label="getSettings.ariaLabel"
          :v-maska="getSettings.vMask"
        />
        <div
          v-if="errors[0] && !disabled"
          class="invalid-feedback"
          :id="`${inputId}-error`"
        >
          <font-awesome-icon :icon="['far', 'exclamation-circle']" fixed-width />
          {{ translateError(errors, label || name) }}
        </div>
      </template>
    </span>
  </validation-provider>
</template>

<script lang="ts">
import '@/vee-validate-rules.ts';
import { Getter } from 'vuex-facing-decorator';
import { Component, Prop, Watch } from 'vue-facing-decorator';
import { Rules } from '@/store/validations/types';
import ValidationAsterisk from '@/components/shared/ValidationAsterisk.vue';
import { format as dateFns } from 'date-fns';
import { isMasked } from '@/utils';
import i18n, { i18nMessages } from '@/i18n';
import { mixins } from "vue-facing-decorator";
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { useCurrentPageStore } from '@/stores/currentPage';

@Component({
  components: {
    ValidationAsterisk
  },
  ...i18nMessages([
    require('@/components/shared/_locales/DateInput.json'),
  ]),
  emits: [
    'update:modelValue',
  ],
})
export default class DateInput extends mixins(TranslationUtilsMixin) {
  @Getter('getRuleSet', { namespace: 'validations' }) private ruleSet!: Rules;
  @Getter('getRules', { namespace: 'validations' }) private getRules!: (ruleSet: any, ruleKey: string, rules: string) => any;
  @Getter('isReadOnly', { namespace: 'validations' }) private isReadOnly!: (readonly?: any) => boolean;
  @Getter('translateError', { namespace: 'utilities' }) private translateError!: (error?: any, field?: string|null) => string;

  // V-model
  @Prop() modelValue!: string;

  // Standard properties
  @Prop({ required: true }) inputId!: string; // MANDATORY actual HTML element ID, set indirectly using properties like 'inputId' and 'selectId'
  @Prop({ required: true }) name!: string; // Field name, also used as the label

  @Prop({ default: null }) validationId!: string; // OPTIONAL specify a 'vid' property for validation-provider, if it must be different than the element ID
                                                  // used by parent component after attempting to save to decide where server-side validation errors are shown
  @Prop({ default: null }) label!: string; // Alternate Label property
  @Prop({ default: false }) disabled!: boolean; // Turn input data entry off
  @Prop({ default: false }) append!: boolean; // Input label addon
  @Prop({ default: '' }) appendText!: string; // Input label addon
  @Prop({ default: false }) calculated!: boolean|string // Show Calculated indicator
  @Prop({ default: 'Calculated' }) calculatedText!: string; // Customize label for Calculated indicator
  @Prop({ default: false }) readonly!: boolean; // Render input as if it were plain text and turn input data entry off
  @Prop({ default: false }) hideLabel!: boolean; // Hide label visually, while still being readable for screen readers

  @Prop({ default: null }) rules!: string; // OPTIONAL lets us hard-code the client-side vee-validate rules in the front-end instead of using anything provided by the back-end
  @Prop({ default: null }) ruleKey!: string // OPTIONAL parameter path to load client-side validation e.g. new_validations, edit_validations
                                            // used by input components to set 'rules' properties in their validation providers based on the client-side validations loaded from the back-end
  @Prop({ default: false }) legacy!: boolean; // flag to use legacy date control, set to true to use duet-date-picker
  @Prop({ default: null }) crossValues!: any; // valus needed for cross field validation for the asterix
  @Prop({ default: null }) minDateLimit!: any; // Minimum date limit
  @Prop({ default: null }) maxDateLimit!: any; // Maximum date limit
  @Prop({ default: null }) direction!: any; // direction
  @Prop({ default: false }) isLoading!: boolean;


  // Since we are delegating between the duet-date-control and our custom input we need
  // to have keep track of the state. DuetDate Picker does not like having invalid values sent to it
  // it just clears it out. So we use this **HACK** to keep track of a last known good state just so that
  // the date picker is happy, while we can send the invalid value up to the
  // component as needed.
  private validatedValue: string|null = null;
  private datePickerValue: string|null = null;
  private invalidValue: string|null = null;
  private lastGoodKnownValue: any = null;

  // masked input
  get isMasked(): boolean {
    if (!this.modelValue) return false;
    return isMasked(this.modelValue.toString());
  }

  get validationProvider() {
    return this.$refs.provider as any;
  }

  // Format input value as some forms return bad data
  // We use the lastGoodKnownValue as a check to keep the date picker happy
  private sanitizedDatePickerValue(): any {
    const val = this.invalidValue ? (this.lastGoodKnownValue ? this.lastGoodKnownValue : null) : this.modelValue;
    return this.formatValue(val);
  }

  get getDefaultMinDate(): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 40); // default current - 40 years
    return (date.toISOString()).substring(0, 10); // return iso date-only string
  }

  get getDefaultMaxDate(): string {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1); // default current + 1 year
    return (date.toISOString()).substring(0, 10); // return iso date-only string
  }

  get getMinDateLimit(): string {
    // do this as we can't access methods inside the default prop
    return this.minDateLimit ? this.minDateLimit : this.getDefaultMinDate;
  }

  get getMaxDateLimit(): string {
    // do this as we can't access methods inside the default prop
    return this.maxDateLimit ? this.maxDateLimit : this.getDefaultMaxDate;
  }

  get getSettings(): any {
    switch (useCurrentPageStore().configuration.dateFormat) {
      case("en"):
        return {
          buttonLabel: this.$t('choose_date').toString(),
          placeholder: this.$t('date_format.en').toString(),
          selectedDateMessage: this.$t('selected_date_is').toString(),
          prevMonthLabel: this.$t('previous_month').toString(),
          nextMonthLabel: this.$t('next_month').toString(),
          monthSelectLabel: this.$t('month').toString(),
          yearSelectLabel: this.$t('year').toString(),
          closeLabel: this.$t('close_window').toString(),
          keyboardInstruction: this.$t('you_can_use_arrow_keys').toString(),
          calendarHeading: this.$t('choose_a_date').toString(),
          dayNames: [this.$t('sunday').toString(), this.$t('monday').toString(), this.$t('tuesday').toString(), this.$t('wednesday').toString(), this.$t('thursday').toString(), this.$t('friday').toString(), this.$t('saturday').toString()],
          monthNames: [this.$t('january').toString(), this.$t('february').toString(), this.$t('march').toString(), this.$t('april').toString(), this.$t('may').toString(), this.$t('june').toString(), this.$t('july').toString(), this.$t('august').toString(), this.$t('september').toString(), this.$t('october').toString(), this.$t('november').toString(), this.$t('december').toString()],
          monthNamesShort: [this.$t('jan').toString(), this.$t('feb').toString(), this.$t('mar').toString(), this.$t('apr').toString(), this.$t('may').toString(), this.$t('jun').toString(), this.$t('jul').toString(), this.$t('aug').toString(), this.$t('sep').toString(), this.$t('oct').toString(), this.$t('nov').toString(), this.$t('dec').toString()],
          locale: 'en-GB',
          format: "dd-MM-yyyy", // format for date-fns
          ariaLabel: this.$t('entry_pattern_is_numeric.en'), // aria label
          vMask: "##-##-####", // v-mask
          regex: /^(\d{2})\-?(\d{2})\-?(\d{4})$/,
          createDate: function(matches: any) { return new Date(matches[3], matches[2] -1, matches[1]); }
        };

        break;
      case("iso"):
        return {
          buttonLabel: this.$t('choose_date').toString(),
          placeholder: this.$t('date_format.iso').toString(),
          selectedDateMessage: this.$t('selected_date_is').toString(),
          prevMonthLabel: this.$t('previous_month').toString(),
          nextMonthLabel: this.$t('next_month').toString(),
          monthSelectLabel: this.$t('month').toString(),
          yearSelectLabel: this.$t('year').toString(),
          closeLabel: this.$t('close_window').toString(),
          keyboardInstruction: this.$t('you_can_use_arrow_keys').toString(),
          calendarHeading: this.$t('choose_a_date').toString(),
          dayNames: [this.$t('sunday').toString(), this.$t('monday').toString(), this.$t('tuesday').toString(), this.$t('wednesday').toString(), this.$t('thursday').toString(), this.$t('friday').toString(), this.$t('saturday').toString()],
          monthNames: [this.$t('january').toString(), this.$t('february').toString(), this.$t('march').toString(), this.$t('april').toString(), this.$t('may').toString(), this.$t('june').toString(), this.$t('july').toString(), this.$t('august').toString(), this.$t('september').toString(), this.$t('october').toString(), this.$t('november').toString(), this.$t('december').toString()],
          monthNamesShort: [this.$t('jan').toString(), this.$t('feb').toString(), this.$t('mar').toString(), this.$t('apr').toString(), this.$t('may').toString(), this.$t('jun').toString(), this.$t('jul').toString(), this.$t('aug').toString(), this.$t('sep').toString(), this.$t('oct').toString(), this.$t('nov').toString(), this.$t('dec').toString()],
          locale: 'en-US',
          format: "yyyy-MM-dd", // format for date-fns
          ariaLabel: this.$t('entry_pattern_is_numeric.iso'), // aria label
          vMask: "####-##-##", // v-mask
          regex: /^(\d{4})\-?(\d{2})\-?(\d{2})$/,
          createDate: function(matches: any) { return new Date(matches[1], matches[2] -1, matches[3]); }
        };
        break;
    }
  }

  get getLocalizationSettingsForDuet(): any {
    const object = Object.fromEntries(
      Object.entries(this.getSettings)
        .filter(([key]) => [
          'buttonLabel',
          'placeholder',
          'selectedDateMessage',
          'prevMonthLabel',
          'nextMonthLabel',
          'monthSelectLabel',
          'yearSelectLabel',
          'closeLabel',
          'keyboardInstruction',
          'calendarHeading',
          'dayNames',
          'monthNames',
          'monthNamesShort',
          'locale',
          ].includes(key))
    );
    return object;
  }

  private isDateArrayValid(matches: any[]|null): boolean {
    if (!matches) return false;
    if (matches.length < 4) return false;

    let day, month, year = null;

    switch (useCurrentPageStore().configuration.dateFormat) {
      case("en"):
        day = matches[1];
        month = matches[2];
        year = matches[3];
        break;
      case("iso"):
        day = matches[3];
        month = matches[2];
        year = matches[1];
        break;
      default:
        return false;
        break;
    }

    if (year < 1600 || year > 9999 || month == 0 || month > 12 || day == 0 || day > 31) {
      return false; // invalid date
    } else {
      return true; // valid date
    }
  }

  public dateAdapter(): any {
    const _vm = this as DateInput;
    return Object.assign({},
      // parent listeners
      this.$attrs.listeners,
      {
        parse(value = "") {
          const provider = _vm.$refs.provider as any;
          const matches = value.match(_vm.getSettings.regex);

          const validMatch = _vm.isDateArrayValid(matches);
            provider.reset();

          if (validMatch) {
            // If we have the matches then we have a good value and we set it up.
            _vm.invalidValue = null;
            _vm.lastGoodKnownValue = value;
            return _vm.getSettings.createDate(matches);
          } else {
            // Invalid value to be passed to the parent.
            _vm.invalidValue = value;

            // only set error message if user entered value
            if (matches) {
              const message = i18n.tc(`validation.messages.date.${useCurrentPageStore().configuration.dateFormat}`);
              provider.setErrors(message);
            }
            return null;
          }
        },
        format(date: any) {
          // generate value for UI using chosen format
          return dateFns(date, _vm.getSettings.format);
        }
      }
    );
  }

  // Apply validation rules
  get formRules(): any {
    return this.getRules(this.ruleSet, this.ruleKey, this.rules);
  }

  mounted() {
    // If a value was set up on creation we can assume that it's a valid one and set it
    // up as the last good known value.
    if(this.modelValue) {
      this.lastGoodKnownValue = this.modelValue;
      this.validationProvider.reset({ value: this.modelValue });
      this.datePickerValue = this.sanitizedDatePickerValue();
    }
  }

  @Watch('modelValue')
  onModelValue(): void {
    this.validationProvider.reset({ value: this.modelValue });
    this.datePickerValue = this.sanitizedDatePickerValue();
  }

  // Strip time portion off ISO datetime string
  formatValue(value: string): string {
    return value ? value.substring(0, 10) : value;
  }

  // Forward events to the parent component
  public maskedInputEvents(): any {
    const _vm = this as DateInput;
    if (this.legacy) {
      return Object.assign({},
        // parent listeners
        this.$attrs.listeners,
        {
          // custom listeners
          input(event: any) {
            // Emit updated value for v-model
            _vm.$emit('update:modelValue', event.target.value);
          }
        }
      );
    } else {
      return Object.assign({},
        // parent listeners
        this.$attrs.listeners,
        {
          // custom listeners
          input(event: any) {
            // We only want to bubble up the invalidValue as bubbling all values will mess up the
            // duet date picker as it already has the value it needs.
            if(_vm.invalidValue) {
              _vm.$emit('update:modelValue', _vm.invalidValue);
            }
          },
          duetChange(event: any) {
            // In case of date selection from the calendar, we also have to set up the values
            _vm.lastGoodKnownValue = event.detail.value;
            _vm.invalidValue = null;
            // Emit updated value on change
            _vm.$emit('update:modelValue', event.detail.value.length > 0 ? event.detail.value : null);
          },
          duetClose(event:any) {
            // Evaluate value when date picker closed
            // Note: Duet date-picker includes two un-documented events. duetOpen & duetClose
            const valid = event.target.value ? !isNaN(Date.parse(event.target.value)) : false;
            if (valid) {
              // if date chosen is valid, reset validation provider (clear errors)
              const provider = _vm.$refs.provider as any;
              provider.reset();
            }
          }
        }
      );
    }
  }
}
</script>

<style>
.form-control.hydrated {
  padding: 0 !important;
}

duet-date-picker {
  visibility: inherit;
}

.duet-date__input {
  padding: 7px 60px 8px 14px !important;
  border-color: #ced4da;
}

.duet-date__input:disabled {
  background-color: rgb(242, 242, 242);
}

.duet-date__toggle {
  background-color: rgb(242, 242, 242);
}

.duet-date__input:focus, .duet-date__toggle:focus {
  border-color: #80bdff !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}


.is-invalid .duet-date__input {
  border-color: #dc3545;
}
</style>
