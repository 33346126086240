import { countDecimals, sanitizeArray, isNull, isValidFloatFormat } from './utils';
import { defineRule, configure } from 'vee-validate';
import i18n from '@/i18n';
import { useCurrentPageStore } from '@/stores/currentPage';
import { TagObject } from '@/store/utilities/types';

import { 
  between,
  email,
  integer, 
  numeric,
  max_value,
  min_value,
  regex,
  required,
} from '@vee-validate/rules';

configure({
  generateMessage: (context) => {
    const rule = context?.rule?.name;
    return i18n.tc(`validation.messages.${rule}`).toString();
  },
});

// define only the rules we need, smaller payload size
defineRule('between', between);

defineRule('email', email);
defineRule('integer', integer);
defineRule('numeric', numeric);

defineRule('min_limit', min_value);
defineRule('min_value', min_value);
defineRule('max_limit', max_value);
defineRule('max_value', max_value);

defineRule('regex', regex);

defineRule('computed_required', required);
defineRule('required', required);

// Rules that do not need validated in the UI can be defined with this API-only stub validation
const apiOnlyValidation = (value: unknown) => { return true; };

// boolean validation handled on server side
defineRule('boolean', apiOnlyValidation);

/**
 * must_be_checked
 * @param value
 * @returns {boolean} true if equals true
 */
defineRule('must_be_checked', (value: any) => {
  if (value === true) return true;

  return 'validation.messages.must_be_checked';
});

/**
 * float, must be a float
 * @param value
 * @returns {boolean} true if value blank or valid number
 */
defineRule('float', (value: any) => {
  if (isValidFloatFormat(value)) return true;

  return 'validation.messages.float';
});

/**
 * max_length, length must be lower or equal limit
 *
 * NOTE: Here we are using spread operator to count number of unicode code points, because using
 * the string 'length' function on its own will return the number of 16-bit code units. Instead
 * we want to count the number of 'characters' to align with the authoratative back-end validation
 *
 * @param length
 * @returns {boolean} true if value boolean
 */
defineRule('max_length', (value: string|null, limit: number) => {
  if (value == null) return true;

  const numCharacters = [...value].length;
  if (numCharacters <= Number(limit)) return true;

  return `validation.messages.max_length:${limit}`;
});

/**
 * exact_length, length must be equal specified length
 *
 * @param length
 * @returns {boolean} true if value boolean
 */
defineRule('exact_length', (value: string|null, length: any) => {
  const numCharacters = value ? value.length : 0;
  if (numCharacters == Number(length)) return true;

  return `validation.messages.exact_length:${length}`;
});

/**
 * value_between, must have decimal places
 * @param low, high
 * @returns {boolean} true if value boolean
 */
defineRule('value_between', (value: number, args: Record<string, string>, ctx) => {
  if (value == null) return true;

  const low = args[0];
  const high = args[1];

  const valid = (value as number) >= parseFloat(low) && (value as number) <= parseFloat(high) ? true : false;
  if (valid) return true;

  return `validation.messages.value_between:${low},${high}`;
});

/**
 * decimal_places, allow upto x decimal places
 * @param places
 * @returns {boolean} true if value boolean
 */
defineRule('decimal_places', (value: any, places: any) => {
    const hasPlaces = countDecimals(value);
    if (hasPlaces <= parseInt(places)) return true;

    return `validation.messages.decimal_places:${places}`;
});

/**
 * not_blank, value must not be blank 
 * @param none
 * @returns {boolean} true if value boolean
 */
defineRule('not_blank', (value: any) => {
  return value !== '' && value !== undefined ? true : 'validation.messages.not_blank';
});

// /**
//  * permitted_only_when_true, validation against boolean 
//  * @param target (field)
//  * @returns {boolean} true unless target true
//  */
defineRule('permitted_only_when_true', (value: any, target: any) => {
  const valid = !target && !value ? false : true;
  if (valid) return true;

  return `validation.messages.permitted_only_when_true:${target}`;
});

// /**
//  * filled_only_when_true, validation against boolean 
//  * @param target (field)
//  * @returns {boolean} true unless target true
//  */
defineRule('filled_only_when_true', (value: any, target: any) => {
  const valid = (target && value) || (!target && !value) ? false : true;
  if (valid) return true;

  return `validation.messages.filled_only_when_true:${target}`;
});

// /**
//  * blank_if_value, validation against boolean 
//  * @param trigger_field, trigger_value
//  * @returns {boolean} true unless target true
//  */
defineRule('blank_if_value', (value: any, trigger_field: any, trigger_value: any) => {
  // shows message based on validate returning false
  const trigger_matched = (!trigger_field && trigger_field == trigger_value);
  const valid = !value && trigger_matched ? false : true;
  if (valid) return true;

  return `validation.messages.blank_if_value:${trigger_field}`;
});

defineRule('blank_unless_value', apiOnlyValidation);

// /**
//  * blank_unless_includes, validation against array
//  * @param target, choices
//  * @returns {boolean} true, valid if blank unless target included in choices list
//  */
defineRule('blank_unless_includes', (value: string, args: string[], ctx) => {
  const valid = !value || args.includes(value);

  return `validation.messages.blank_unless_includes:${value}`;
});

/**
 * required_if_includes, validation against array
 * @param target, choices
 * @returns {boolean} true unless target & possible choices
 */
defineRule('required_if_includes', (value: any, args: Record<string, unknown>, ctx) => {
  const sanitizedChoices = sanitizeArray(args.choices);
  const valid = isNull(value) && sanitizedChoices.includes(args.target) ? false : true;
  if (valid) return true;

  return 'validation.messages.required_if_includes';
});

// /**
//  * required_if_value, validation against boolean 
//  * @param trigger_field, trigger_value
//  * @returns {boolean} true unless target true
//  */
defineRule('required_if_value', (value: any, trigger_value: any, trigger_field: any) => {
//   // shows message based on validate returning false
    const trigger_matched = !!trigger_field && trigger_field == trigger_value;
    const valid = !trigger_matched || (value && trigger_matched);
    if (valid) return true;

    return 'validation.messages.required_if_value';
});

/**
 * required_if_has_value, value required if trigger_field has trigger_value
 * @param trigger_field
 * @param trigger_value
 * @returns {boolean}
 */
defineRule('required_if_has_value', (value: any, args: Record<string, unknown>, ctx) => {
  const trigger_matched = args.trigger_field && args.trigger_field == args.trigger_value;
  const valid = isNull(value) && trigger_matched ? false : true;
  if (valid) return true;

  return 'validation.messages.required_if_has_value';
});

/**
 * one_of, validation against boolean 
 * @param none
 * @returns {boolean} true if value boolean
 */
defineRule('one_of', (value: unknown, target: any) => {
  if ((typeof value === "boolean") || (value == 0 || value == 1)) return true;

  return `validation.messages.one_of:${target}`;
});

/**
 * required_if, valid is target is true and has value / target is false 
 * @param target (field)
 * @returns {boolean} true unless target true
 */
defineRule('required_if', (value: any, args: Record<string, unknown>, ctx) => {
  const valid = ((value !== null && value !== undefined && value >= 0) ? value.toString() : value) && (args.target == true) || args.target == false;
  if (valid) return true;

  return 'validation.messages.required_if';
});

/**
 * required_if_filled, field is required if target has value  
 * @param target (field)
 * @returns {boolean} true unless target true
 */
defineRule('required_if_filled', (value: unknown, [target]: [string]) => {
  const valid = !target || target && value ? true : false;
  if (valid) return true;

  return 'validation.messages.required_if_filled';
});

// /**
//  * required_unless_filled, field is required if target has no value  
//  * @param target (field)
//  * @returns {boolean} true unless target true
//  */
defineRule('required_unless_filled', (value: any, target: string) => {
  const valid = (isNull(value) && target) || isNull(value) && isNull(target) ? false : true;
  if (valid) return true;

  return 'validation.messages.required_unless_filled';
});

// /**
//  * required_unless, valid if target is false and has value / target is true
//  * @param target (field)
//  * @returns {boolean} true unless target false
//  */
defineRule('required_unless', (value: any, target: string) => {
  const valid = value || target && !value;
  if (valid) return true;

  return 'validation.messages.required_unless';
});

// /**
//  * date_year_min, validates year against minimum value
//  * @param limit (minimum year value)
//  * @returns {boolean} true if above minimum year
//  */
defineRule('date_year_min', (value: string, limit: any) => {
    const dateObj = new Date(value);
    const year = dateObj.getFullYear();
    if (year > limit) return true;
    return `validation.messages.date_year_min:${limit}`;
});

// /**
//  * date_year_max, validates year against maximum value 
//  * @param limit (maximum year value)
//  * @returns {boolean} true if below maximum year
//  */
defineRule('date_year_max', (value: string, limit: any) => {
  const dateObj = new Date(value);
  const year = dateObj.getFullYear();
  if (year < limit) return true;
  return `validation.messages.date_year_max:${limit}`;
});

/**
 * datetime, validates against valid datetime, allows optional
 * @param none
 * @returns {boolean} true if valid date time 
 */
defineRule('datetime', (value: string|null) => {
  if (!value) return true;

  // if 4 digits (time)
  if (value.length <= 5) {
    const timeRegex = /^[012]\d{1}:\d{2}$/; // 24 hr pattern
    const isTimeValid = timeRegex.exec(value) !== null;
    if (isTimeValid) return true;
    return 'validation.messages.datetime';
  } else {
    // else date
    const isDateValid = !isNaN(Date.parse(value));
    if (isDateValid) return true;
    return 'validation.messages.datetime';
  } 
});

/**
 * date, validates against valid date, allows optional
 * @param none
 * @returns {boolean} true if valid date time 
 */
defineRule('date', (value: string|null) => {
  if (!value) return true;

  const valid = !isNaN(Date.parse(value));
  if (valid) return true;

  return `validation.messages.date.${useCurrentPageStore().configuration?.dateFormat}`;
});

/**
 * in_lookup, valid if value is in lookup
 * @param lookup
 * @returns {boolean}
 */ 
defineRule('in_lookup', (value: string, args: string[], ctx) => {
  if (!value || args.includes(value)) return true;

  return 'validation.messages.in_lookup';
});

defineRule('only_if_lookup_other', apiOnlyValidation);

defineRule('only_unless_lookup_other', apiOnlyValidation);

// /**
//  * required_if_lookup_other, Required field, valid only if "other" selected
//  * NOTE: assumes we only perform client-side validation on field when "other" selected
//  * @param target, lookup
//  * @returns {boolean}
//  */ 
defineRule('required_if_lookup_other', (value: any, lookup: string, target: any) => {
  const valid = !!value;
  if (valid) return true;

  return 'validation.messages.required_if_lookup_other_required';
});

// /**
//  * object_identifier, validates value against object identifier type
//  * @param object
//  * @returns {boolean} true if object identifier
//  */
  defineRule('object_identifier', (object: any, target: any) => {
    // get guid
    const value = object && object.$oid ? object.$oid : object;
    // validate guid;
    if (value.match(/^[0-9a-fA-F]{24}$/)) return true;

    return `validation.messages.object_identifier:${target}`;
  });

/**
 * format_mask, regex must be equal specified regex
 *
 * @param regex
 * @returns {boolean} true if value boolean
 */
defineRule('format_mask', apiOnlyValidation);

/**
 * must_be_value_if, valid if property is in included values only when target field is true
 * @param target
 * @param included_values
 * @returns {boolean} true if value included, or if target false
 */
defineRule('must_be_value_if', (value: any, [target, included_values]: [any, any[]], ctx) => {
  const included = included_values.includes(value);
  const valid = !target || included;
  if (valid) return true;

  return 'validation.messages.must_be_value_if';
});

/**
 * cannot_be_value_if, valid if property does not match excluded values only when target field is true
 * @param target
 * @param excluded_values
 * @returns {boolean} true if value not excluded, or if target false
 */
defineRule('cannot_be_value_if', (value: any, [target, excluded_values]: [any, any[]], ctx) => {
  const excluded = excluded_values.includes(value);
  const valid = !target || !excluded;
  if (valid) return true;

  return `validation.messages.cannot_be_value_if:${excluded_values},${target}`;
});

// required_with_exemptions validation handled on server side
defineRule('required_with_exemptions', apiOnlyValidation);

// newer_than_history validation handled on server side
defineRule('newer_than_history', apiOnlyValidation);

// not_after_today validation handled on server side
defineRule('not_after_today', apiOnlyValidation);

// not_before_date validation handled on server side
defineRule('not_before_date', apiOnlyValidation);

// postal_code validation handled on server side
defineRule('postal_code', apiOnlyValidation);

// zip_code validation handled on server side
defineRule('zip_code', apiOnlyValidation);

// not_after_today validation handled on server side
defineRule('not_after_today', apiOnlyValidation);

// in_nested_lookup validation handled on server side
defineRule('in_nested_lookup', apiOnlyValidation);

// unique validation handled on server side
defineRule('unique', apiOnlyValidation);

// unique validation handled on server side
defineRule('unique_within_relation', apiOnlyValidation);

/**
 * field must not contain any tags with ti-invalid class
 * note: intended to be used with vue-tags-input
 * @returns {boolean} true only if no invalid tags
 */
defineRule('no_invalid_tags', (value: unknown, tags: any, target: any) => {
  if (!tags || !tags.length) return true;

  const invalidTags = (tags || []).filter((tag: TagObject) => {
    const tiClasses = tag.tiClasses || [];
    return tiClasses.includes('ti-invalid');
  });
  if (invalidTags.length === 0) return true;

  return `validation.messages.no_invalid_tags:${target}`;
});

/**
 * required rule converted to work with vue-tags
 * 
 * note: intended to be used with vue-tags-input
 * 
 * @returns {boolean} true if tags contains no items
 */
 defineRule('required_tags', (value: any, tags: any) => {
  if (tags.length > 0) return true;

  return 'validation.messages.required_tags';
});

/**
 * less_than_or_equal_to rule
 *
 * @param value
 * @param args
 * @returns {boolean} true / false
 */
defineRule('less_than_or_equal_to', (value: number, args: number[], ctx) => {
  const limit = args ? Number(args) : null;
  if (!value || !limit) return true;
  if (value <= limit) return true;

  return `validation.messages.less_than_or_equal_to:${limit}`;
});

/**
 * greater_than_or_equal_to rule
 *
 * @param value
 * @param args
 * @returns {boolean} true / false
 */
defineRule('greater_than_or_equal_to', (value: number, args: number[], ctx) => {
  const limit = args ? Number(args) : null;
  if (!value || !limit) return true;
  if (value >= limit) return true;

  return `validation.messages.greater_than_or_equal_to:${limit}`;
});

// StringArray validation handled on server side
defineRule('StringArray', apiOnlyValidation);
