import { APIPostTransplantFollowUpConfig } from "@/APIModels/configuration/features/types";
import { UIFeatureConfig } from "@/UIModels/configuration/features";

export class UIPostTransplantConfig {
  public enabled: boolean;
  public transferHistory: UIFeatureConfig;

  public constructor(apiSource: APIPostTransplantFollowUpConfig) {
    this.enabled = apiSource.enabled;
    this.transferHistory = new UIFeatureConfig(apiSource.transfer_post_transplant);
  }
}
